import React, { useEffect, useMemo, useState } from 'react';
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { isCompanyAdminUser } from "../../../utils/AccessControl";
import AccessRequired from "../settings/components/AccessRequired";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { getAllLineReports, LineReportLine, toCsvDownload } from "../../../services/ReportingService";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { TableContents } from "../../UI/atoms/TableStructure";
import Colours from "../../UI/atoms/Colours";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { BodyLarge } from "../../UI/atoms/fonts/Body";
import { SpaceBetweenRow } from "../settings/StyleComponents";


export function LineReportsReportingPage(props: Props) {
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const [lineReports, setLineReports] = useState<LineReportLine[]>([]);
  const canViewReport = useMemo(() => isCompanyAdminUser(currentUser), [currentUser]);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (canViewReport) {
      if (!isCompanyAdminUser(currentUser)) {
        dispatch(failureNotification(`You must be a company admin`));
        return;
      }

      setLoading(true)
      getAllLineReports()
        .then(lineReports => setLineReports(lineReports))
        .catch(() => dispatch(failureNotification("Failed to get report data")))
        .finally(() => setLoading(false));
    }
  }, [canViewReport, dispatch, currentUser]);

  const exportAsCsv = () => {
    toCsvDownload(lineReports, 'line-reports');
  }

  return (
    <ReportingContainer>
      <GoBackButton url={"/manage/reporting"} />
      {loading && <LoadingSpinner fullScreen={true} />}
      <div>
        {canViewReport ? <>
          <SpaceBetweenRow>
            <HeadlineLarge style={{marginBottom: 24}}>{t('reports-page.line-reports-title')}</HeadlineLarge>
            <OutlineButton text={"Export as CSV"} click={exportAsCsv} disabled={loading || lineReports.length === 0} />
          </SpaceBetweenRow>
          <>
            <table>
              <thead>
                <th className="table__userCell" style={{marginLeft: 'auto'}}>
                  <BodyLarge weight={600}>Manager</BodyLarge>
                </th>
                <th className="table__userCell">
                  <BodyLarge weight={600}>Line report</BodyLarge>
                </th>
                <th className="table__userCell">
                </th>
              </thead>
              <tbody>
              {lineReports.map((lineReportLine: LineReportLine, key: number) => (
                <tr key={key}>
                  <LineReportRow lineReport={lineReportLine} />
                </tr>
              ))}
              </tbody>
            </table>
          </>
        </> : <>
          <AccessRequired visible={!canViewReport} teamAdminsRequired={false} companyAdminsRequired={true} />
        </>}
      </div>
    </ReportingContainer>
  )
}

function LineReportRow(props: {lineReport: LineReportLine}) {
  const {lineReport} = props;
  return <>
    <td className="table__userCell" style={{marginLeft: 'auto'}}>
      <TableContents colour={Colours.black}>
        {`${lineReport.managerFirstName} ${lineReport.managerLastName}`}
      </TableContents>
    </td>
    <td className="table__userCell">
      <TableContents colour={Colours.black}>
        {`${lineReport.reporteeFirstName} ${lineReport.reporteeLastName}`}
      </TableContents>
    </td>
    <td className="table__userCell">
      <TableContents>
      </TableContents>
    </td>
  </>
}

interface Props {
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 24px;
`
